module.exports = {
  siteUrl: 'https://cmsdirektwerbung.de',
  siteTitle: 'CMS Direktwerbung', // <title>
  siteLanguage: 'de',
  manifestName: 'CMS Direktwerbung',
  manifestShortName: 'CMS', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/logos/website-icon.png',
  pathPrefix: '', // This path is subpath of your hosting https://domain/portfolio
  keywords: 'werbung, direktwerbung, fyler verteilen',
  
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-whatsapp',
      name: 'Whatsapp',
      url: 'https://wa.me/<number>',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:info@cms-direktwerbung.de',
    },
  ],
};
